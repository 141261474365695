export const carouselPrincipal = [
  {
    img: "/images/slider/app.jpg",
    link: "#appRow",
  },
  {
    img: "/images/slider/planes.jpg",
    link: "https://toyotaplan.com.ar/",
  },
  {
    img: "/images/slider/usados.jpg",
    link: "https://store.autoprana.com.ar/#/usados",
  },
  {
    img: "/images/slider/kinto.jpg",
    link: "https://store.autoprana.com.ar/#/kinto",
  },
  {
    img: "/images/slider/accesorios.jpg",
    link: "https://store.autoprana.com.ar/#/accesorios",
  },
];

export const carouselSecundario = [
  {
    img: "/images/slider/sliderSecun/hilux.png",
    modelo: "Hilux",
    url: "hilux",
    position: "7",
  },
  {
    img: "/images/slider/sliderSecun/corollaCross.png",
    modelo: "Corolla Cross",
    url: "corollacross",
    position: "12",
  },

  {
    img: "/images/slider/sliderSecun/hiace.png",
    modelo: "Hiace Wagon",
    url: "hiacewagon",
    position: "10",
  },
  {
    img: "/images/slider/sliderSecun/corollaSport.png",
    modelo: "Corolla GR-Sport",
    url: "corollagr-sport",
    position: "5",
  },
  {
    img: "/images/slider/sliderSecun/sw4.png",
    modelo: "SW4",
    url: "sw4",
    position: "14",
  },
  {
    img: "/images/slider/sliderSecun/corolla.png",
    modelo: "Corolla",
    url: "corolla",
    position: "4",
  },
];
