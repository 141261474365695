import React from "react";

import Slider from "../common/Slider";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { carouselPrincipal, carouselSecundario } from "../data/SliderData";

import { InstagramEmbed } from "react-social-media-embed";

export default function Home({ modeloSeleccionado }) {
  return (
    <>
      <Row className="m-0 p-0">
        <Col className="m-0 mt-4 p-0">
          <Slider
            images={carouselPrincipal}
            objectToRender="image"
            infiniteLoop={true}
          />
        </Col>
        <Col>
          <Col
            md={{ span: 8, offset: 2 }}
            style={{ marginTop: "80px", marginBottom: "30px" }}
          >
            <h5>Galería de Modelos</h5>
            <h3>
              <b>Modelos Destacados</b>
            </h3>
          </Col>

          <Slider
            infiniteLoop={true}
            images={carouselSecundario}
            objectToRender="expandedCard"
            modeloSeleccionado={modeloSeleccionado}
          />
        </Col>
      </Row>
      {/* <Row className="m-0 p-0">
        <Col>
          <Row>
            <Col
              xs={12}
              className="text-center"
              style={{ whiteSpace: "pre-line" }}
            >
              <h2>
                instagram {"\n"} <b>toyotaprana</b>
              </h2>
            </Col>

            <Col xs={12}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <InstagramEmbed
                  url="https://www.instagram.com/p/CwXlRYZOVOn/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
                  width={328}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row className="m-0 p-0">
        <Col xs={12} md={{ span: 8, offset: 2 }} style={{ marginTop: "40px" }}>
          <Row className="m-0 p-0">
            <Col style={{ whiteSpace: "pre-line" }}>
              <h2>
                Conocé la {"\n"} <b>tecnología híbrida de Toyota</b>
              </h2>
            </Col>
          </Row>
          <Row className="m-0 p-0 justify-content-md-center">
            <Col xs={12} md={8}>
              <Image src="/images/homeNews/hibridos.png" width="100%" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        className="mx-0 p-0"
        style={{ marginTop: "80px", marginBottom: "50px" }}
      >
        <Col
          xs={12}
          md={{ span: 4, offset: 2 }}
          style={{ whiteSpace: "pre-line" }}
        >
          Tecnología Híbrida{"\n"}
          {"\n"}
          <h2>
            <b>
              Ahorro de combustible, reducción de emisiones, generación de su
              propia energía reciclada
            </b>
          </h2>
        </Col>
        <Col
          xs={12}
          md={{ span: 3, offset: 1 }}
          style={{ whiteSpace: "pre-line" }}
        >
          <h4>
            Pioneros en tecnología híbrida para más de 10 millones de
            conductores.
          </h4>
          {"\n"}
          Cuando una tecnología como la híbrida te da tanto más, se podría
          pensar que es complicado tener un híbrido. En absoluto. De hecho, los
          híbridos fueron diseñados para hacer la vida más fácil...
        </Col>
      </Row>
      <hr></hr>
      <Row
        className="mx-0 p-0"
        style={{ marginTop: "60px", marginBottom: "40px" }}
      >
        <Col
          xs={12}
          md={{ span: 4, offset: 2 }}
          style={{ whiteSpace: "pre-line" }}
        >
          ¡Nueva App!{"\n"}
          {"\n"}
          <h2>
            <b>
              Prana Store | Todo lo que un concesionario te puede ofrecer...
              ONLINE!
            </b>
          </h2>
          <Row>
            <Col style={{ marginTop: "40px" }}>
              <span>
                ¡Descargá la app, y disfruta de acceder a Prana desde donde vos
                quieras. Reserva tu próximo vehículo 0km o Usado, también tu
                turno al taller. Podes cotizar tu usado y muchas cosas mas!
              </span>
            </Col>
          </Row>
          <Row className="m-0 mt-5 p-0">
            <a href="https://store.autoprana.com.ar/" target="_self">
              <Button
                variant="outline-dark"
                style={{ borderRadius: "15px" }}
                className="px-5"
              >
                Ver más
              </Button>
            </a>
          </Row>
        </Col>
        <Col
          xs={12}
          md={{ span: 4, offset: 0 }}
          style={{ whiteSpace: "pre-line", marginTop: "1%" }}
          className="text-center"
          id="appRow"
        >
          <a href="https://store.autoprana.com.ar">
            <Image src="/images/logos/logo.png" width="250px" />
          </a>
          <Row className="m-0 mt-5 p-0">
            <Col className="m-0 p-0" xs={12}>
              <a href="https://play.google.com/store/apps/details?id=io.ionic.prana">
                <Image src="/images/logos/google-play.svg" width="180px" />
              </a>
              <a href="https://apps.apple.com/us/app/prana/id1504767228">
                <Image src="/images/logos/app-store.svg" width="165px" />
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}
